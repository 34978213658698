import React from "react";
import styled from "styled-components";

import { StyledButton, Title, TitleWrapper } from "./styledComponents";

export const AppStoreLogo = styled.img`
  width: 27px;
  height: 32px;
  opacity: 1;

  @media (max-width: 375px) {
    width: 16px;
    height: 20px;
  }
`;

export const AppStore = styled.img`
  margin-bottom: -5px;
  width: 86px;
  height: 20px;
  opacity: 1;

  @media (max-width: 375px) {
    width: 57px;
    height: 13px;
  }
`;

function AppStoreIcon() {
  return (
    <StyledButton>
      <AppStoreLogo src="/images/mobile/app-store-logo.svg" alt="logo" />
      <TitleWrapper>
        <Title>Загрузите в</Title>
        <AppStore src="/images/mobile/app-store.svg" alt="App Store" />
      </TitleWrapper>
    </StyledButton>
  );
}

export default AppStoreIcon;
