import React, { useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import useNodeObserverOnce from "../../hook/useNodeObserverOnce";

import {
  ContentSection,
  ContentTitle,
  ContentSubtitle,
  ContentText
} from "./styledComponents";

const Content = styled.div`
  width: 100%;
  background-color: var(--blue);
`;

const ContentSectionBlue = styled(ContentSection)`
  margin-bottom: 0;
  padding: 6.75rem 0 10rem;
  color: white;
  background-position: right 6.75rem;

  :after {
    content: "";
    background-color: #0044A3;
    opacity: 0.2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }

  @media (max-width: 768px) {
    background-position: right 5rem;
    margin-top: 7.5rem;
    margin-bottom: 0;
    padding-bottom: 7.5rem;
  }

  @media (max-width: 375px) {
    background-position: right 4rem;
    margin-top: 5rem;
    padding-bottom: 5rem;
  }
`;

const ContentTitleBlue = styled(ContentTitle)`
  max-width: 37.5rem;
  transform: translate3d(0, 100%, 0);

  @media (max-width: 768px) {
    max-width: 20.5rem;
    min-height: unset;
    transform: translate3d(0, 75%, 0);
  }

  @media (max-width: 375px) {
    max-width: 12.875rem;
    min-height: unset;
    transform: translate3d(0, 25%, 0);
  }
`;

const ContentSubtitleBlue = styled(ContentSubtitle)`
  transform: translate3d(0, 100%, 0);
  margin-top: 12.25rem;
  margin-left: 50%;

  @media (max-width: 768px) {
    margin-top: calc(2.5rem + 1.875rem);
    margin-bottom: 3rem;
    margin-left: auto;
  }

  @media (max-width: 375px) {
    margin-top: 2rem;
    margin-bottom: 2.5rem;
    transform: translate3d(0, 25%, 0);
  }
`;

const ContentTextBlue = styled(ContentText)`
  width: 18rem;
  padding-bottom: 0.5rem;
  border-bottom: 0.125rem solid white;
  margin-top: 4.5rem;

  & + & {
    margin-top: 3rem;

    @media (max-width: 768px) {
      margin-top: 2rem;
    }
  }

  @media (max-width: 768px) {
    width: 16.5rem;
    margin-top: 7.5rem;
  }

  @media (max-width: 375px) {
    width: 15.5rem;
    margin-top: 2.5rem;
  }
`;

function ContentBlockBlue({ title, subtitle, description, image }) {
  const contentRef = useRef(null);
  const isVisible = useNodeObserverOnce(contentRef, "-10%");

  return (
    <Content className="blue">
      <ContentSectionBlue
        className="container"
        image={image}
        ref={contentRef}
        isVisible={isVisible}
      >
        <ContentTitleBlue isVisible={isVisible}>{title}</ContentTitleBlue>
        {subtitle && (
          <ContentSubtitleBlue isVisible={isVisible}>
            {subtitle}
          </ContentSubtitleBlue>
        )}
        {description &&
          description.map(text => (
            <ContentTextBlue key={text} isVisible={isVisible}>
              {text}
            </ContentTextBlue>
          ))}
      </ContentSectionBlue>
    </Content>
  );
}

ContentBlockBlue.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.string.isRequired
};

export default ContentBlockBlue;
