import { axiosInstance } from "./API";

export function getBodyTypes() {
  return axiosInstance.get(`/filters/types/body`);
}

export function getTransportTypes() {
  return axiosInstance.get(`/filters/types/transport`);
}

export function getScopeTypes() {
  return axiosInstance.get(`/filters/types/scope`);
}

export function getTrends(transport, body, scope, loading_city = '', unloading_city = '', loading_district = '', unloading_district = '', start, end) {
  return axiosInstance.get(`/trends/?transport=${transport}&body=${body}&scope=${scope}&loading_city=${loading_city}&unloading_city=${unloading_city}&loading_district=${loading_district}&unloading_district=${unloading_district}&start=${start}&end=${end}`)
}
export function getFiltersData(transport, body, scope, loading_city = '', unloading_city = '', loading_district = '', unloading_district = '', start, end) {
  return axiosInstance.get(`/filters/?transport=${transport}&body=${body}&scope=${scope}&loading_city=${loading_city}&unloading_city=${unloading_city}&loading_district=${loading_district}&unloading_district=${unloading_district}&start=${start}&end=${end}`)
}