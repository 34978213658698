import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

export const Select = styled.div`
  width: 250px;
  margin-right: 20px;
  margin-bottom: ${props => (props.skipBottomOffset ? '0' : '30px')};
`;

export const Fields = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
