import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useNodeObserverOnce from '../../hook/useNodeObserverOnce';
import colors from '../../constants/colors';

const Icon = styled.img`
   {
    height: 10.25rem;
    padding-right: 2.125rem;
    transition: 640ms ease-in-out;
    transition-delay: 480ms;
    transform: translate3d(
      ${props => {
        if (props.isVisible) {
          return "0, 0, 0";
        }
        switch (props.animateTo) {
          case "down":
            return "0, -5rem, 0";
          case "up":
            return "0, 5rem, 0";
          case "right":
            return "-5rem, 0, 0";
          case "left":
            return "5rem, 0, 0";
          default:
            return "0, 0, 0";
        }
      }}
    );
    @media (min-width: 376px) and (max-width: 768px) {
      height: 6.6875rem;
    }
    @media (max-width: 376px) {
      height: 6.5rem;
      padding-right: 1.375rem;
    }
  }
`;

const Wrapper = styled.div`
   {
    display: flex;
    justify-content: ${props => (props.isRightAlign ? "flex-end" : "start")};
    opacity: ${props => (props.isVisible ? 1 : 0)};
    transition: 640ms ease-in-out;
    transition-delay: 480ms;
    margin-bottom: 3rem;
    @media (max-width: 376px) {
      display: block;
    }
  }
`;

const Content = styled.div`
   {
    display: flex;
    border-top: 0.125rem solid ${colors.coal};
    max-width: 44rem;
    padding-top: 3.125rem;
    padding-bottom: 1.5rem;
    box-sizing: border-box;
    overflow: hidden;
    @media (min-width: 768px) {
      width: 570px;
    }
    @media (min-width: 376px) and (max-width: 768px) {
      max-width: 25.5rem;
    }
    @media (max-width: 376px) {
    }
  }
`;
const Inner = styled.div`
   {
    max-width: 570px;
    @media (min-width: 376px) and (max-width: 768px) {
      max-width: 16.75rem;
    }
    @media (max-width: 376px) {
      width: 100%;
      h3 {
      }
    }
    h3 {
      margin-top: 0;
      margin-bottom: 1.5rem;
      line-height: 3.5rem;
    }
    p {
      padding: 0;
      margin: 0;
    }
  }
`;

function IconCard({ title, text, icon, isRightAlign, isTopAlign, animateTo }) {
  const cardRef = useRef(null);
  const isVisible = useNodeObserverOnce(cardRef, "-20%");
  return (
    <Wrapper isRightAlign={isRightAlign} ref={cardRef} isVisible={isVisible}>
      <Content isTopAlign={isTopAlign}>
        {icon && (
          <Icon src={icon} isVisible={isVisible} animateTo={animateTo} />
        )}
        <Inner>
          <h3>{title}</h3>
          <div>{text}</div>
        </Inner>
      </Content>
    </Wrapper>
  );
}

IconCard.defaultProps = {
  isRightAlign: false,
  isTopAlign: false
};

IconCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.string,
  isRightAlign: PropTypes.bool,
  isTopAlign: PropTypes.bool,
  animateTo: PropTypes.string
};

export default IconCard;
