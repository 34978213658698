import styled from "styled-components";

export const InputWrapper = styled.div`
  position: relative;
`;

export const InputStyled = styled.input`
  width: 100%;
  height: 60px;
  font-size: 16px;
  padding-right: 28px;
  padding-left: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #1f2733;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #d3dbe6;
  background-color: #f5f8fc;
  line-height: 1.25;
  cursor: pointer;
  font-family: GTPressuraPro, sans-serif;
`;

export const Icon = styled.img`
  position: absolute;
  cursor: pointer;
  right: 5px;
  top: 50%;
  opacity: 0.6;
  transform: translateY(-50%) rotate(0deg);
`;
