import styled from "styled-components";

export const Wrapper = styled.div`
  margin-bottom: 30px;
`;

export const Switcher = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: GTPressuraProBold, sans-serif;
`;

export const Button = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 75px;
  padding: 15px;
  
  background-color: ${props => (props.isActive ? "#f5f8fc" : "#ffffff")};
  border: ${props =>
    props.isActive ? "2px solid #0044a3" : "2px solid #d3dbe6"};
  box-sizing: border-box;
  font-size: 14px;
  font-weight: normal;
  color: #1f2733;
  line-height: 20px;
  font-family: GTPressuraPro, sans-serif;
  cursor: pointer;
  transition-duration:0.2s;
  opacity:${props => (props.disabled ? '0.4' : '1')};
  ${props => (props.disabled ? 'pointer-events:none;' : '')}
 
  span {
    opacity: ${props => (props.isActive ? "1" : "0.6")};
  }
`;
