import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import colors from '../../constants/colors'

const getTheme = (theme) => {
  switch (theme) {
    case 'outline': return { bg: 'transparent', font: colors.traffic, border: `2px solid ${colors.traffic}` }
    case 'coral': return { bg: colors.coral, font: colors.snow, border: `2px solid ${colors.coral}` }
    default: return { bg: colors.traffic, font: 'white', border: 'none' }
  }
}

const getHover = (theme) => {
  switch (theme) {
    case 'outline': return { bg: colors.traffic, font: 'white' }
    case 'coral': return { bg: colors.coral, font: colors.snow }
    default: return { bg: colors.traffic, font: 'white' }
  }
}

const getSize = (size) => {
  switch (size) {
    case 'small':
      return '40px'
    case 'medium':
      return '50px'
    case 'large':
      return '60px'
    case 'largest':
      return '70px'
    default:
      return '60px'
  }
}

const CustomButton = styled.button`
  width: 100%;
  background: ${ props => getTheme(props.theme).bg};
  color: ${ props => getTheme(props.theme).font};
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  cursor: pointer;
  height: ${props => getSize(props.size)};
  border: ${ props => getTheme(props.theme).border};
  border-radius: 5px;
  transition: background .3s ease, color .3s ease;
  
  &:disabled {
   color: ${colors.onyx};
   opacity: .6;
   border-color: ${colors.onyx};
   pointer-events: none;
  }
  
  &:hover {
    background: ${ props => getHover(props.theme).bg};
    color: ${ props => getHover(props.theme).font};
  }
`;

function Button({ text, onClick, theme, size, ...buttonProps }) {
  return <CustomButton {...buttonProps} theme={theme} onClick={onClick} size={size}>{text}</CustomButton>;
}

Button.propTypes = {
  text: PropTypes.any,
  onClick: PropTypes.func,
  theme: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  theme: 'traffic',
  size: 'large',
}

export default Button;
