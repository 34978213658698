import React from "react";
import styled from "styled-components";

import { StyledButton, Title, TitleWrapper } from "./styledComponents";

export const GooglePlayLogo = styled.img`
  width: 29px;
  height: 32px;

  @media (max-width: 375px) {
    width: 19px;
    height: 20px;
  }
`;

export const GooglePlay = styled.img`
  width: 96px;
  height: 20px;

  @media (max-width: 375px) {
    width: 68px;
    height: 13px;
  }
`;

function GooglePlayIcon() {
  return (
    <StyledButton>
      <GooglePlayLogo src="/images/mobile/google-play-logo.svg" alt="logo" />
      <TitleWrapper>
        <Title>Доступно в</Title>
        <GooglePlay src="/images/mobile/google-play.svg" alt="Google Play" />
      </TitleWrapper>
    </StyledButton>
  );
}

export default GooglePlayIcon;
