import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL || 'https://api.tradeplace.msharks.ru/api/v1/index';

// const username = 'dl';
// const password = 'fM4tS3cH7zF0qC4o';
const basicAuth = 'Basic ZGw6Zk00dFMzY0g3ekYwcUM0bw==' //+ window.btoa(username + ':' + password);

const default_headers = {
  Authorization: basicAuth,
};

export const axiosInstance = axios.create({
  baseURL: API_URL,
  responseType: 'json',
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
  },
);

axiosInstance.interceptors.request.use(
  async (config) => {
    const res = await new Promise((resolve) => {
      const newConfig = {
        ...config,
        headers: { ...default_headers },
      };

      resolve(newConfig);
    });

    return res;
  },
  (e) => Promise.reject(e),
);
