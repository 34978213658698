import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

export const Wrapper = styled.div`
  font-size: 16px;
  line-height: 1.25;
  color: #1f2733;
  opacity: 0.8;
  margin-bottom: 5px;
`;

function Label({ text }) {
  return <Wrapper>{text}</Wrapper>;
}

Label.propTypes = {
  text: PropTypes.string
};

export default Label;
