import React, { useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import useNodeObserverOnce from "../../hook/useNodeObserverOnce";

import {
  ContentSection,
  ContentTitle,
  ContentSubtitle,
  ContentText
} from "./styledComponents";

const ContentSectionReverse = styled(ContentSection)`
  background-position: left top;

  @media (max-width: 768px) {
    margin-bottom: calc(7.5rem + 4.5rem);
  }
`;

const ContentTitleReverse = styled(ContentTitle)`
  margin-left: auto;
`;

const ContentSubtitleReversed = styled(ContentSubtitle)`
  margin-top: 0;
  margin-left: auto;

  @media (max-width: 768px) {
    transform: ${({ isTopOffset }) =>
      isTopOffset ? "translateY(75%)" : "translateY(0)"};
  }
`;

function ContentBlockReverse({ title, subtitle, description, image }) {
  const contentRef = useRef(null);
  const isVisible = useNodeObserverOnce(contentRef, "-10%");

  return (
    <ContentSectionReverse
      className="container"
      image={image}
      ref={contentRef}
      isVisible={isVisible}
    >
      <ContentTitleReverse isVisible={isVisible}>{title}</ContentTitleReverse>
      {subtitle && (
        <ContentSubtitleReversed
          isVisible={isVisible}
          isTopOffset={!!description}
        >
          {subtitle}
        </ContentSubtitleReversed>
      )}
      {description &&
        description.map(text => (
          <ContentText key={text} isVisible={isVisible}>
            {text}
          </ContentText>
        ))}
    </ContentSectionReverse>
  );
}

ContentBlockReverse.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.string.isRequired
};

export default ContentBlockReverse;
