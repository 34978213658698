import React from 'react';
import SVGIcon from './index';
import PropTypes from 'prop-types';
import colors from '../../../constants/colors';

const IconMeetingRoom = (props) => {
  return (
    <SVGIcon {...props} viewBox="0 0 30 30">
      <path fill={colors[props.color]} fillRule="evenOdd" d="M17 9v15H6v-2h2V6h9v1h5v15h2v2h-4V9h-3zm-4 5v2h2v-2h-2z"/>
    </SVGIcon>
  );
};

IconMeetingRoom.propTypes = {
  color: PropTypes.string
};

export default IconMeetingRoom;
