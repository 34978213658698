import styled from "styled-components";

export const StyledButton = styled.button`
  color: var(--white);
  background-color: var(--blue);
  border: 1px solid rgba(255, 255, 255, 0.24);
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: 60px;
  width: 184px;
  flex-direction: row;
  padding: 10px;
  margin: 8px;

  :hover {
    border-color: var(--white);
    cursor: pointer;
  }

  @media (max-width: 375px) {
    height: 40px;
    width: 116px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-left: 18px;

  @media (max-width: 375px) {
    margin-left: 9px;
  }
`;

export const Title = styled.p`
  text-transform: uppercase;
  font-size: 14px;
  line-height: 14px;
  margin: 0;

  @media (max-width: 375px) {
    display: none;
  }
`;
