import React from 'react';
import SVGIcon from './index';
import PropTypes from 'prop-types';
import colors from '../../../constants/colors';

const IconCall = (props) => {
  return (
    <SVGIcon {...props} viewBox="0 0 30 30">
      <path
        d="m24 18.46-5.27-.61-2.52 2.52c-2.83-1.44-5.15-3.75-6.59-6.59l2.53-2.53-.61-5.25h-5.51c-.58 10.18 7.79 18.55 17.97 17.97z"
        fill={colors[props.color]}/>
    </SVGIcon>
  );
};

IconCall.propTypes = {
  color: PropTypes.string
};

export default IconCall;
