import styled from "styled-components";
import { Link as GLink } from "gatsby";

export const Wrapper = styled.div`
  @media (max-width: 375px) {
    display: none;
  }
`;

export const MenuWrapper = styled.div`
  background-color: var(--blue);
  z-index: 100;

  @media (max-width: 768px) {
    display: none;
  }

  @media (max-width: 375px) {
    display: none;
  }
`;
export const StickyMenuWrapper = styled(MenuWrapper)`
  width: 100%;
  position: fixed;
  top: 0;

  @media (max-width: 768px) {
    display: block;
  }
`;
export const LinksWrapper = styled.menu`
  display: flex;
  align-items: center;
  height: 96px;
  margin: 0;
  padding: 0;
  justify-content: space-between;
  border-top: 1px solid rgba(255, 255, 255, 0.24);
  box-sizing: border-box;
  position: relative;

  @media (max-width: 768px) {
    height: 80px;
  }
`;
export const StickyLinksWrapper = styled(LinksWrapper)`
  border-top: none;
`;
export const OrderLink = styled(GLink)`
  margin-left: 0;
  
  &.active > span > span {
    color: var(--white);
    opacity: 1;
  }

  @media (max-width: 768px) {
    margin-left: 2.5rem;
  }
`;
export const CargoLink = styled(GLink)`
  margin-left: 2.5rem;
  
  &.active > span > span {
    color: var(--white);
    opacity: 1;
  }
`;
export const Link = styled.a`
  margin-left: auto;
`;

export const ExitLink = styled(GLink)`
  margin-left: 2.5rem;
  display: block;
`;
export const Logo = styled.span`
  height: 2rem;
  width: 2rem;
  background-image: url("/images/logo/traffic-logo-shape.svg");
  background-repeat: no-repeat;
  position: absolute;
  left: calc(100vw / -28.5 - 1rem);
  display: block;

  @media (max-width: 768px) {
    position: static;
  }

  @media (min-width: 1440px) {
    top: 2rem;
    left: calc(-2.25rem - 2rem);
  }
`;
export const Stub = styled.div`
  height: 96px;
  display: block;

  @media (max-width: 768px) {
    display: none;
  }
`;
