import styled from "styled-components";

export const CommonSuggestion = styled.div`
  line-height: 20px;
  color: #1f2733;
  font-size: 16px;
  padding: 15px 20px;
  background-color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #f5f6fc;
  }
`;
