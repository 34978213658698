import React from "react";
import styled from "styled-components";
import { StaticLink } from "../LinksCollection";
import { Link } from "../MenuCollection/styledComponents";
import ButtonWithIcon from '../ButtonsCollection/ButtonWithIcon'
import colors from '../../constants/colors'
import IconCall from '../Icons/SVGIcons/IconCall'
import IconMail from '../Icons/SVGIcons/IconMail'
import IconMeetingRoom from '../Icons/SVGIcons/IconMeetingRoom'

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8.4375rem;
  border-bottom: 1px solid ${colors.traffic};
`;

const Logo = styled.div`
  display: flex;
`;

// const LogoShape = styled.img`
//   width: 2.125rem;
//   height: 2.125rem;
//   transform: scaleX(-1);
//
//   @media (max-width: 375px) {
//     width: 1.5rem;
//     height: 1.5rem;
//   }
// `;

const LogoText = styled.img`
  width: 14.75rem;
  height: auto;
  //margin-left: 1.125rem;

  @media (max-width: 375px) {
    width: 10.25rem;
    height: 1.5rem;
    margin-left: 0.75rem;
  }
`;

const TrendsText = styled.span`
  font-size: 16px;
  font-family: GTPressuraPro, sans-serif;
  color: ${colors.traffic};
  line-height: 1.25;
  letter-spacing: normal;
`;

const TrendsStaticLink = styled(StaticLink)`
  /*padding: 15px 0;*/
  color: #0043a3;
  font-size: 16px;
  line-height: 1.25;
  margin-left: 50px;
  opacity: 1;
  text-align: right;
  text-transform: none;
  transition: all 240ms ease-in-out;
  &:hover {
    opacity: 1;
  }
`;

const ContactWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

function TrendsHeader() {
  return (
    <Header className="container">
      <Logo>
        <LogoText
          src="/images/logo/traffic-logo.svg"
          alt="Logo text"
        />
      </Logo>
      <ContactWrapper>
        <Link href="tel:8 800 70-70-071">
          <TrendsStaticLink>
            <ButtonWithIcon uppercase={false} color="traffic" icon={<IconCall width="30" height="30" />}>
              <TrendsText> 8 800 70-70-071</TrendsText>
            </ButtonWithIcon>
          </TrendsStaticLink>
        </Link>
        <Link href="mailto:ai@traffic.online">
          <TrendsStaticLink>
            <ButtonWithIcon uppercase={false} color="traffic" icon={<IconMail width="30" height="30" />}>
              <TrendsText>ai@traffic.online</TrendsText>
            </ButtonWithIcon>
          </TrendsStaticLink>
        </Link>
        <Link href="https://traffic.online/login" target="_blank" rel="noopener">
          <TrendsStaticLink>
            <ButtonWithIcon uppercase={false} color="traffic" icon={<IconMeetingRoom width="30" height="30" />}>
              <TrendsText> Личный кабинет</TrendsText>
            </ButtonWithIcon>
          </TrendsStaticLink>
        </Link>
      </ContactWrapper>
    </Header>
  );
}

export default TrendsHeader;
