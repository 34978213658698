import styled from "styled-components";

export const ContentSection = styled.section`
  margin-top: calc(6.75rem + 6.5rem);
  margin-bottom: 6.75rem;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-position: right top;
  background-origin: border-box;
  min-height: 25rem;

  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: 640ms ease-in-out;
  transform: translate3d(
    ${props => (props.isVisible ? "0,0,0" : "0,calc(6.75rem + 6.5rem),0")}
  );

  @media (max-width: 768px) {
    margin-top: calc(5rem + 4.5rem);
    margin-bottom: 6.75rem;
    background-position: right top;
    background-size: calc(100vw - 50% + 4.5rem + 1.25rem);
  }

  @media (max-width: 375px) {
    background-size: calc(100vw - 50% + 3rem + 2.25rem);
    margin-bottom: 3rem;
  }
`;

export const ContentTitle = styled.h2`
  margin-left: 0;
  margin-bottom: 0;
  line-height: 6.5rem;
  text-transform: uppercase;
  max-width: 50.5rem;

  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: 640ms ease-in-out;
  transition-delay: 360ms;
  transform: translate3d(
    ${props => (props.isVisible ? "0,-50%,0" : "0,50%,0")}
  );

  &::selection {
    background-color: var(--blue);
  }

  @media (max-width: 768px) {
    min-height: 13.5rem;
  }

  @media (max-width: 375px) {
    min-height: 9rem;
  }
`;

export const ContentSubtitle = styled.h4`
  margin-top: -4.5rem;
  margin-bottom: 10rem;
  margin-left: 0;
  max-width: 37.5rem;

  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: 640ms ease-in-out;
  transition-delay: 380ms;
  transform: translate3d(${props => (props.isVisible ? "0,0,0" : "0,50%,0")});

  &::selection {
    background-color: var(--blue);
  }

  @media (max-width: 768px) {
    max-width: 24.5rem;
    margin: 7.5rem auto 0;
  }

  @media (max-width: 375px) {
    max-width: 15.5rem;
    margin: 3rem auto -3rem;
    padding-bottom: 4rem;
  }
`;

export const ContentText = styled.p`
  max-width: 24.5rem;
  margin-left: 50%;

  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: 640ms ease-in-out;
  transition-delay: 400ms;
  transform: translate3d(${props => (props.isVisible ? "0,0,0" : "0,50%,0")});
  padding-top: 5rem;

  & + & {
    padding-top: 0;
  }

  &::selection {
    background-color: var(--blue);
  }

  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    padding-top: 1.5rem;
  }
  @media (max-width: 375px) {
    max-width: 15.5rem;
    padding-top: 0;
  }
`;
