import React from 'react';
import SVGIcon from './index';
import PropTypes from 'prop-types';
import colors from '../../../constants/colors';

const IconMail = (props) => {
  return (
    <SVGIcon {...props} viewBox="0 0 30 30">
      <path fill={colors[props.color]} fillRule="evenOdd" d="M25 7H5v16h20V7zm-2 4l-8 5-8-5V9l8 5 8-5v2z"/>
    </SVGIcon>
  );
};

IconMail.propTypes = {
  color: PropTypes.string
};

export default IconMail;
