import { useEffect, useState } from "react";

function useNodeObserver(ref, rootMargin = "0px") {
  const [isShowing, setShowing] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entity]) => {
        setShowing(entity.isIntersecting);
      },
      { rootMargin }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.unobserve(ref.current);
    };
  }, []);

  return isShowing;
}

export default useNodeObserver;
