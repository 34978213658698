import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import isEqual from 'lodash/isEqual';

import { InputWrapper, InputStyled, Icon } from '../styles.js';
import { Common } from '../OptionLists';

/**
 * Получение значения input
 * @param suggestion
 * @returns {string}
 */
function getSuggestionValue(suggestion) {
  return suggestion ? suggestion.label : '';
}

/**
 * Получение значения input при мульти выборе
 * @param selectedSuggestions
 * @param allSuggestions
 * @returns {string|*}
 */
function getMultiSuggestionValue(selectedSuggestions, allSuggestions) {
  if (selectedSuggestions.length === 0) {
    return '';
  }

  if (selectedSuggestions.length === 1) {
    return selectedSuggestions[0].label;
  }

  return `Выбранно ${selectedSuggestions.length} из ${allSuggestions.length}`;
}

function getDefaultSuggestionsValue(props) {
  if (!props.value) {
    return props.isMulti ? [] : null;
  }

  return props.value;
}

function filterOptions(value) {
  return function(option) {
    return option.label.toLowerCase().includes(value.toLowerCase());
  };
}

/**
 * Получение state для компонента
 * @param props
 * @returns {{suggestion: (Array|null|*), inputValue: (string|*), suggestions: *}}
 */
function getState(props) {
  const suggestion = getDefaultSuggestionsValue(props);
  const suggestions = props.options;
  const inputValue = props.isMulti
    ? getMultiSuggestionValue(suggestion, suggestions)
    : getSuggestionValue(suggestion);

  return {
    inputValue,
    suggestions,
    suggestion
  };
}

function stopEvent(event) {
  // предотвращаем submit формы (при выборе через enter)
  // предотвращаем повторный фокус input (при выботе через клик)
  event.preventDefault();
  event.stopPropagation();
}

/**
 * TODO: это пропатченная версия
 * оригинал здесь
 * https://gitlab.dellin.ru/TRADEPLACE/WebUI/apps/tradeplace/blob/traffic/src/components_new/DropdownCollection/SimpleDropdown/index.js
 */
class SimpleDropdown extends Component {
  state = getState(this.props);

  /**
   * Задать новое значение формы
   * @param props
   * @param state
   * @return {null|{inputValue: *}}
   */
  static getDerivedStateFromProps(props, state) {
    if (state.inputValue !== props.value) {
      return {
        inputValue: props.value
      };
    }
    return null;
  }

  /**
   * Событие при изменении input
   * @param event
   * @param newValue
   */
  onChange = (event, { newValue }) => {
    const { onChange } = this.props;
    this.setState({
      inputValue: newValue
    });

    if (onChange) {
      onChange();
    }
  };

  /**
   * События при снятии фокуса с input
   */
  onBlur = () => {
    const { onBlur } = this.props;

    if (onBlur) {
      onBlur();
    }
  };

  /**
   * Получение значений выпадающего списка
   * @param value
   */
  onSuggestionsFetchRequested = ({ value }) => {
    const { options, isSearchable } = this.props;

    if (value && isSearchable) {
      this.setState({
        suggestions: options.filter(filterOptions(value))
      });
    } else {
      this.setState({
        suggestions: options
      });
    }
  };

  /**
   * Получение значений выпадающего списка при мульти выборе
   */
  onMultiSuggestionsFetchRequested = () => {
    const { options } = this.props;

    this.setState({
      suggestions: options
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  /**
   * Событие при выборе
   */
  onSuggestionSelected = (event, { suggestion }) => {
    const { onSelect } = this.props;
    this.setState({ suggestion });
    onSelect(suggestion);
    stopEvent(event);
  };

  /**
   * Событие при мульти выборе
   */
  onMultiSuggestionSelected = (event, suggestionObject) => {
    const { onSelect } = this.props;
    const { suggestion } = this.state;
    const newSuggestion = suggestionObject.suggestion;

    const newSuggestionArray = suggestion.includes(newSuggestion)
      ? suggestion.filter(item => item !== newSuggestion)
      : suggestion.concat(newSuggestion);

    this.setState({
      suggestion: newSuggestionArray
    });

    onSelect(newSuggestionArray);
    stopEvent(event);
  };

  /**
   * Запись ссылки на input ref
   */
  storeInputReference = autosuggest => {
    if (autosuggest !== null) {
      this.input = autosuggest.input;
    }
  };

  render() {
    const {
      placeholder,
      isSearchable,
      className,
      renderOption,
      isMulti,
      disabled,
      options
    } = this.props;

    const { inputValue, suggestion, suggestions } = this.state;

    const option =
      options.length && inputValue
        ? options.find(({ value }) => isEqual(value, inputValue))
        : null;

    const inputProps = {
      ...this.props,
      placeholder,
      // Для отображения label выбранной опции
      value: option ? option.label : '',
      disabled,
      onChange: this.onChange,
      onBlur: this.onBlur,
      className,
      // если мультивыбор, то input только на чтение
      readOnly: isMulti ? true : !isSearchable
    };

    delete inputProps.onSelect;
    delete inputProps.isSearchable;
    delete inputProps.options;
    delete inputProps.renderOption;
    delete inputProps.isMulti;

    const componentProps = {
      suggestions,
      onSuggestionsClearRequested: this.onSuggestionsClearRequested,
      inputProps,
      // показываем выпадающий список при пустом input
      shouldRenderSuggestions: () => true,
      focusInputOnSuggestionClick: false,
      ref: this.storeInputReference,
      renderInputComponent: inputProps => {
        return (
          <InputWrapper>
            <InputStyled {...inputProps} ref={inputProps.ref} />
            <Icon
              onClick={() => this.input.focus()}
              src="/images/icons/ic-keyboard-arrow-down.svg"
            />
          </InputWrapper>
        );
      }
    };

    return isMulti ? (
      <Autosuggest
        {...componentProps}
        renderSuggestion={Common}
        onSuggestionsFetchRequested={this.onMultiSuggestionsFetchRequested}
        onSuggestionSelected={this.onMultiSuggestionSelected}
        getSuggestionValue={() =>
          getMultiSuggestionValue(suggestion, suggestions)
        }
      />
    ) : (
      <Autosuggest
        {...componentProps}
        renderSuggestion={renderOption}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
      />
    );
  }
}

SimpleDropdown.defaultProps = {
  isSearchable: true,
  isMulti: false,
  renderOption: Common
};

SimpleDropdown.propTypes = {
  // callback при выборе значения
  onSelect: PropTypes.func.isRequired,
  // массив значений выпадающего списка
  options: PropTypes.array.isRequired,
  // выбранное значение
  // для одного значения объект
  // для мульти значений массив объектов
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  // вид выпадающего списка
  renderOption: PropTypes.func,
  // поиск по значениям выпадающего списка
  isSearchable: PropTypes.bool,
  // разрешает выбор нескольких значений
  isMulti: PropTypes.bool,

  // input props
  // callback на изменение input
  onChange: PropTypes.func,
  // callback на снятие фокуса с input
  onBlur: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default SimpleDropdown;
