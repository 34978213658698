import styled from "styled-components";

export const Wrapper = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
`;

export const Label = styled.div`
  background-color: ${props => (props.active ? "#0044a3" : "#ffffff")};
  border: 2px solid #0044a3;
  color: ${props => (props.active ? "#ffffff" : "#0044a3")};
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 60px;
  font-size: 14px;
  cursor: pointer;
  line-height: 1.43;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  
  &:first-child {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-right-width: 0;
  }
  
  &:last-child {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-left-width: 0;
  }
`;
