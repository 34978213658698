import { useState } from "react";
import useNodeObserver from "./useNodeObserver";

function useNodeObserverOnce(ref, rootMargin = "0px") {
  const isVisible = useNodeObserver(ref, rootMargin);
  const [isShowing, setShowing] = useState(isVisible);
  if (!isShowing && isVisible) {
    setShowing(isVisible);
  }
  return isShowing;
}

export default useNodeObserverOnce;
