import React, { useCallback, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Label from '../Label';
import { Wrapper, Button, Switcher } from './styles';
import { getBodyTypes } from "../../service/index.service";

function BodyTypeSwitcher({ value, onChange, bodyTypesArr }) {

  const [bodyTypesList, setBodyTypesList] = useState([]);
  const getBodyTypesData = useCallback(async () => {
    try {
      const res = await getBodyTypes();
      setBodyTypesList(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [])

  useEffect(() => {
    getBodyTypesData();
  }, [])

  return (
    <Wrapper>
      <Label text={'Тип кузова'} />

      <Switcher>
        {bodyTypesList?.length > 0 ?
          bodyTypesList.map((item, index) =>
            <Button
              type='button'
              onClick={() => onChange(item)}
              isActive={value === item}
              key={index}
              disabled={!item.includes(bodyTypesArr)}
            >
              <img src={item === 'Тентованный' ? "/images/icons/tent.svg" : item === 'Изотермический' ? "/images/icons/therm.svg" : item === 'Рефрижератор' ? "/images/icons/freez.svg" : "/images/icons/tent.svg"} alt="Тентованный" />
              <span>{item}</span>
            </Button>
          )
          : null}
      </Switcher>
    </Wrapper>
  );
}

BodyTypeSwitcher.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  bodyTypesArr: PropTypes.array,
}

export default BodyTypeSwitcher;
