import styled from "styled-components";

export const FooterWrapper = styled.footer`
  padding-top: 2rem;
  background: var(--blue);
  min-height: 800px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    min-height: 920px;
  }

  @media (max-width: 375px) {
    min-height: 668px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 6rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media (max-width: 375px) {
    flex-direction: column;
    margin-bottom: 3rem;
  }
`;

export const FooterTitle = styled.h1`
  max-width: 600px;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 0;

  @media (max-width: 768px) {
    margin-right: auto;
  }
`;

export const FooterSubtitle = styled.h4`
  color: #ffffff;
  max-width: 18rem;
  margin-bottom: 0;
  margin-left: 0;

  @media (max-width: 768px) {
    max-width: 25.375rem;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 375px) {
    max-width: 15.5rem;
  }
`;

export const MenuLabel = styled.h4`
  margin-top: 2.5rem;
  margin-bottom: 2rem;
  color: var(--white);
  font-size: 1rem;
  opacity: 0.8;

  @media (max-width: 375px) {
    margin-bottom: 1rem;
  }
`;

export const MenuItem = styled.a`
  font-family: GTPressuraPro, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 2.14;
  color: var(--white);
  opacity: 0.64;
  display: block;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  @media (max-width: 375px) {
    margin: 0.75rem 0;
    line-height: 1rem;
  }
`;

export const AppsButtonsWrapper = styled.div`
  display: flex;
  margin: -8px;
  flex-wrap: wrap;
`;

export const LinksContainer = styled.div`
  padding-top: 20px;
  width: 100%;
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap; 
  width: 100%;

  @media (max-width: 768px) {
    max-width: 25.375rem;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 375px) {
    max-width: 15.5rem;
  }
`;

export const Logo = styled(MenuLabel)`
  opacity: 1;
  display: flex;
  margin-bottom: 2.5rem;

  @media (max-width: 768px) {
    order: 1;
    margin-top: 2.5rem;
  }
`;

export const LogoShape = styled.img`
  width: 2.125rem;
  height: 2.125rem;

  @media (max-width: 375px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const LogoText = styled.img`
  width: 12.5rem;
  height: auto;
  

  @media (max-width: 375px) {
    width: 10.25rem;
    height: 1.5rem;
    margin-left: 0.75rem;
  }
`;

export const LogoWrapper = styled.div`
  margin-right: 100px;
  max-width: 23.5%;
  display: flex;
  flex-direction: column;

  ${MenuItem} {
    margin-top: 0;
  }

  @media (max-width: 768px) {
    max-width: 25.375rem;
    margin-top: 2.5rem;
    order: 1;
  }
`;

export const RegistrationWrapper = styled.div`
  max-width: 15%;

  @media (max-width: 768px) {
    max-width: 11.5rem;
  }
`;

export const SupportWrapper = styled.div`
  max-width: 12%;
  margin-left: 2%;

  @media (max-width: 768px) {
    max-width: 11.5rem;
    margin-left: 1.875rem;
  }

  @media (max-width: 375px) {
    margin-left: 0;
  }
`;

export const AppsWrapper = styled.div`
  max-width: 35%;
  margin-left: auto;

  @media (max-width: 768px) {
    max-width: 25.375rem;
    margin-left: 0;
  }
`;

export const CopyrightWrapper = styled.div`
  width: 100%;

  @media (max-width: 768px) {
    max-width: 25.375rem;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 375px) {
    max-width: 15.5rem;
  }
`;
