import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  FooterWrapper,
  TitleWrapper,
  FooterTitle,
  FooterSubtitle,
  LinksContainer,
  LinksWrapper,
  LogoWrapper,
  Logo,
  LogoText,
  MenuItem,
  MenuLabel,
  SupportWrapper,
  CopyrightWrapper
} from "./styledComonents";
import Copyright from "../Copyright";

const TrendsFooterWrapper = styled(FooterWrapper)`
  background-color: #0043a3;
  min-height: auto;
  padding-bottom: 40px; 
`;

// eslint-disable-next-line
const TrendsTitleWrapper = styled(TitleWrapper)`
  align-items: center;

  ${FooterTitle} {
    font-family: GTPressuraProBold, sans-serif;
    font-size: 64px;
    line-height: 1.09;
    color: #fff;
  }

  ${FooterSubtitle} {
    margin-left: 100px;

    font-size: 22px;
    color: #fff;
    line-height: 1.36;
  }
`;

const TrendsLogo = styled(Logo)`
  margin-top: -10px;
`;

const TrendsCopyrightWrapper = styled(CopyrightWrapper)`
  color: #fff;
  opacity: 0.64;
`;

const TrendsSupportWrapper = styled(SupportWrapper)`
  max-width: 270px;

  &:last-child {
    margin-left: 185px;
  }
`;

const TrendsMenuLabel = styled(MenuLabel)`
  margin-top: 0;
  margin-bottom: 22px;
  opacity: 1;
  color: #fff;
`;

const TrendsMenuItem = styled(MenuItem)`
  color: #fff;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
`;

const TrendsLogoWrapper = styled(LogoWrapper)`
  max-width: 100%;
`;

function TrendsFooter() {
  return (
    <TrendsFooterWrapper id="header">
      <LinksContainer className="container">
        <LinksWrapper>
          <TrendsLogoWrapper>
            <TrendsLogo>
              <LogoText
                src="/images/logo/traffic-logo-white.svg"
                alt="Logo text"
              />
            </TrendsLogo>
            <TrendsCopyrightWrapper className="container">
              <Copyright />
            </TrendsCopyrightWrapper>
          </TrendsLogoWrapper>
          <TrendsSupportWrapper>
            <TrendsMenuLabel>Поддержка</TrendsMenuLabel>
            <TrendsMenuItem href="tel:8 800 70-70-071">
              8 800 70-70-071
            </TrendsMenuItem>
            <TrendsMenuItem href="mailto:ai@traffic.online">
              ai@traffic.online
            </TrendsMenuItem>
          </TrendsSupportWrapper>
          <TrendsSupportWrapper>
            <TrendsMenuLabel>Документы</TrendsMenuLabel>
            <TrendsMenuItem href="/files/terms-of-use.pdf" target="_blank">
              Пользовательское соглашение
            </TrendsMenuItem>
            <TrendsMenuItem href="/files/privacy-policy.pdf" target="_blank">
              Политика конфиденциальности
            </TrendsMenuItem>
          </TrendsSupportWrapper>
        </LinksWrapper>
      </LinksContainer>
    </TrendsFooterWrapper>
  );
}

TrendsFooter.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node
};

export default TrendsFooter;
