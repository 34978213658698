import React  from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import IconHelpOutline from '../Icons/SVGIcons/IconHelpOutline';
import colors from '../../constants/colors';
import { isLegacyBrowser } from '../../helpers/browserTools';

const Wrapper = styled.label`
  display: flex;
  align-items: center;
  position: relative;
`;

const Input = styled.input`
  box-sizing: border-box;
  padding: 0;
  appearance: none;
  line-height: normal;
  /* for ipad */
  width: 30px;
  height: 30px;
  border: none;
  
  &:before {
    content: "";
    display: block;
    border: 2px solid #d3dbe6;
    padding: 5px;
    width: 30px;
    height: 30px;
    box-sizing: border-box;
  }
  
  &:checked, &.checked {
    &:before {
      content: url("/images/icons/checked.svg");
      display: block;
      background-color: #0044a3;
      border: 2px solid #0044a3;
      padding: 5px;
      width: 30px;
      height: 30px;
      box-sizing: border-box;
    }
  }
`;

const Text = styled.div`
  margin-left: 10px;
  white-space: nowrap;
`;

const Help = styled(IconHelpOutline)`
  cursor: pointer;
  &:hover {
    path {
      fill: ${colors.traffic};
      fill-opacity: 1;
    }
  }
`;

function CheckBoxInput({ text, onChange, hint, checked = false }) {
  const handleOnClick = () => {};

  return (
    <>
      <Wrapper>
        {isLegacyBrowser ? (
          <Input
            as="span"
            type="checkbox"
            className={checked ? 'checked' : ''}
            defaultChecked={checked}
            onClick={() => onChange(!checked)}
          />
        ) : (
          <Input
            type="checkbox"
            defaultChecked={checked}
            onChange={() => onChange(!checked)}
          />
        )}
        <Text onClick={handleOnClick}>{text}</Text>
        <Help data-tip />
      </Wrapper>
      <ReactTooltip
        place="top"
        type="dark"
        effect="solid"
        className="tooltip-helper"
      >
        { hint }
      </ReactTooltip>
    </>
  );
}

CheckBoxInput.propTypes = {
  text: PropTypes.string,
  onChange: PropTypes.func,
  hint: PropTypes.string,
  checked: PropTypes.bool,
};

export default CheckBoxInput;
