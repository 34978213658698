import React, { useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import useNodeObserverOnce from "../../hook/useNodeObserverOnce";

import {
  ContentSection,
  ContentTitle,
  ContentSubtitle,
  ContentText
} from "./styledComponents";

const ContentSectionBottom = styled(ContentSection)`
  margin-top: 10.875rem;

  @media (max-width: 768px) {
    margin-top: 7.5rem;
  }

  @media (max-width: 375px) {
    margin-top: 5rem;
    margin-bottom: var(--line-height);
  }
`;

const ContentTitleBottom = styled(ContentTitle)`
 transform: translate3d(0, 80%, 0);

    @media (max-width: 375px) {
      transform: translate3d(0, 50%, 0);
    }
`;

const ContentSubtitleBottom = styled(ContentSubtitle)`
  transform: translate3d(0, 100%, 0);
  margin-top: 12.25rem;
  margin-left: 50%;

  @media (max-width: 768px) {
    margin-top: calc(2.5rem + var(--line-height-h4));
    margin-bottom: 3rem;
    margin-left: auto;
  }

  @media (max-width: 375px) {
    margin-top: 2rem;
    margin-bottom: 2.5rem;
    transform: translate3d(0, 25%, 0);
  }
`;

function ContentBlockBottom({ title, subtitle, description, image }) {
  const contentRef = useRef(null);
  const isVisible = useNodeObserverOnce(contentRef, "-10%");

  return (
    <ContentSectionBottom
      className="container"
      image={image}
      ref={contentRef}
      isVisible={isVisible}
    >
      <ContentTitleBottom isVisible={isVisible}>{title}</ContentTitleBottom>
      {subtitle && (
        <ContentSubtitleBottom isVisible={isVisible}>
          {subtitle}
        </ContentSubtitleBottom>
      )}
      {description &&
        description.map(text => (
          <ContentText key={text} isVisible={isVisible}>
            {text}
          </ContentText>
        ))}
    </ContentSectionBottom>
  );
}

ContentBlockBottom.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.string.isRequired
};

export default ContentBlockBottom;
