import React from 'react';
import SVGIcon from './index';
import colors from '../../../constants/colors';

const IconHelpOutline = (props) => {
  return (
    <SVGIcon
      {...props}
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <path
        d="M14 21h2v-2h-2v2zm1-16C9.48 5 5 9.48 5 15s4.48 10 10 10 10-4.48 10-10S20.52 5 15 5zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"
        fill={colors.coal}
        fillOpacity="0.4"
        fillRule="evenodd"
      />
    </SVGIcon>
  );
};

export default IconHelpOutline;
