import React from 'react';
import PropTypes from 'prop-types';

const SVGIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={props.className}
  >
    {props.children}
  </svg>
);

SVGIcon.propTypes = {
  children: PropTypes.node,
  width: PropTypes.string,
  height: PropTypes.string,
  viewbox: PropTypes.string,
  className: PropTypes.string
};

export default SVGIcon;
