export const API_URL = 'https://trends.traffic.online/api/v1/';
/* Короткий формат отображения даты */
export const SHORT_DATE_FORMAT = 'DD.MM.YY';
/* Формат даты для запроса */
export const REQUEST_DATE_FORMAT = 'YYYY-MM-DD';

/* Типы гузова */
export const AWNING = 'awning';
export const ISOTHERMAL = 'isothermal';
export const REFRIGERATOR = 'refrigerator';

export const BODY_TYPE_OBJECTS = {
  [AWNING]: 'Тентованный',
  [ISOTHERMAL]: 'Изотермический',
  [REFRIGERATOR]: 'Рефрижератор'
};
