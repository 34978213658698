import React, { useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import useNodeObserverOnce from "../../hook/useNodeObserverOnce";

import {
  ContentSection,
  ContentTitle,
  ContentSubtitle,
} from "./styledComponents";

const TrendsContentSection = styled(ContentSection).attrs(
  ({ containerStyle }) => ({
    style: containerStyle,
  })
)`
  margin-top: calc(6.25rem + 40px);
  background-size: 822px;
`;

const TrendsContentTitle = styled(ContentTitle)`
  text-transform: none;
  font-family: GTPressuraProBold, sans-serif;
  font-size: 96px;
  line-height: 1.15;
  color: #000;
  max-width: 1170px;
  transform: translateY(-50px);
`;

const TrendsContentSubtitle = styled(ContentSubtitle)`
  max-width: 600px;
  margin-top: 0;
  margin-bottom: 25px;

  font-family: GTPressuraPro, sans-serif;
  font-size: 24px;
  line-height: 1.875rem;
  transform: translateY(-30px);
`;

const Actions = styled.div`
  display: flex;
  max-width: 450px;
`;

const TrendsContentButton = styled.button`
  width: 180px;
  background: ${(props) => (props.primary ? "#0044a3" : "#FFFFFF")};
  color: ${(props) => (props.primary ? "#FFFFFF" : "#0044a3")};
  border: 2px solid #0044a3;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  cursor: pointer;
  height: 60px;
  border-radius: 5px;
  margin-right: 15px;

  &:hover {
    background-color: ${(props) =>
      props.primary ? "rgba(0, 68, 163, 0.8)" : "rgba(242, 242, 242, 0.8)"};
  }
`;

function TrendsContentBlock({
  title,
  subtitle,
  action,
  about,
  onAction,
  onAbout,
  image,
  containerStyle,
}) {
  const contentRef = useRef(null);
  const isVisible = useNodeObserverOnce(contentRef, "-10%");

  return (
    <TrendsContentSection
      className="container"
      image={image}
      ref={contentRef}
      isVisible={isVisible}
      containerStyle={containerStyle}
    >
      <TrendsContentTitle isVisible={isVisible}>{title}</TrendsContentTitle>
      <TrendsContentSubtitle isVisible={isVisible}>
        {subtitle}
      </TrendsContentSubtitle>
      <Actions>
        <TrendsContentButton isVisible={isVisible} onClick={onAction} primary>
          {action}
        </TrendsContentButton>
        <TrendsContentButton isVisible={isVisible} onClick={onAbout}>
          {about}
        </TrendsContentButton>
      </Actions>
    </TrendsContentSection>
  );
}

TrendsContentBlock.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  action: PropTypes.string,
  about: PropTypes.string,
  onAction: PropTypes.func,
  onAbout: PropTypes.func,
  containerStyle: PropTypes.string,
  image: PropTypes.string.isRequired,
};

export default TrendsContentBlock;
