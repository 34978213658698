import React from "react";
import PropTypes from 'prop-types';
import { Label, Wrapper } from "./styles";

function Switcher({ options = [], value, onChange }) {
  return (
    <Wrapper>
      { options.map((option, i) =>
        <Label
          key={i}
          active={option.value === value}
          onClick={() => onChange(option.value)}
        >{ option.label }</Label>)}
    </Wrapper>
  )
}

Switcher.propTypes = {
  options: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

export default Switcher;
